import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { makeWorkCategorySlug } from "../../utils"

const CASESTUDYCATEGORYSECTION = ({active}) => {
    const data = useStaticQuery(graphql`
    query {
        UI {
            dsCaseStudyCategories {
                Name
                Slug
            }    
        }
    }`)
    const WCategory = data.UI.dsCaseStudyCategories
    return(
        <div className="category-postion">
            <Link exact to="/work" className={active === 'All' ? "work-rightheading work-rightheadingblue": "work-rightheading"} >All</Link>        
            {WCategory && WCategory.map((list, i) => (
                <Link exact to={makeWorkCategorySlug(list)}>
                    <div className={list.Slug === active ? "work-rightheading work-rightheadingblue": "work-rightheading"}>{list.Name}</div>
                </Link>
            ))}
        </div>
    )
}

export default CASESTUDYCATEGORYSECTION