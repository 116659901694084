import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import BreadCrumbs from "../components/breadCrumbs";
import AwardsandRecognitions from "../components/AwardsandRecognitions";
import OurlatestTwoSection from "../components/sections/ourLatestTwoPostsBlock";
import CategorySection from "../components/sections/caseStudyCategoryBlock";
import GetInTouch from "../components/GetInTouch";
import Head from '../components/head'
import { makeWorkSingleSlug } from "../utils"

const WorkPage = ({ data }) => {
    const works = data.UI.dsCaseStudies
    const dsWork = data.UI.dsWork
    
    return(
        <Layout> 
            <Head SEO_Tags={dsWork.SeoTag} />
            <BreadCrumbs crumbs={dsWork.Title}/> 
            <section className="BG-F9FAFE work_hero tB-padd-50">
                <div className="container"> 
                    {dsWork.Description ? (
                        <div dangerouslySetInnerHTML={{ __html: dsWork.Description }} data-sal="slide-right" style={{'--sal-duration':`1s`, '--sal-delay':`0.10s`}}/>
                    ) : null}                    
                </div>
            </section>    
            <section className="BG-F9FAFE section_allWork ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-3 col-lg-3 col-sm-12 ds-hide">
                            <CategorySection active={'All'} />
                        </div>
                        <div className="col-md-9 col-lg-10">
                            <div className="tab-content">
                                <div className="tab-pane active" id="control-1" role="tabpanel" aria-labelledby="tab-1">
                                    <div className="row">
                                        {works && works.map((work, i) => {
                                            return(
                                                <div className="col-md-6 col-sm-6 ourWork_col" key={i}>
                                                    <Link exact to={makeWorkSingleSlug(work)}>
                                                        <div className="ourworkColInner">
                                                            {work.FeaturedImage ? (
                                                                <img src={work.FeaturedImage.url} alt={work.FeaturedImage.alternativeText} />
                                                            ) : null}
                                                            {work.Title ? (<h4>{work.Title}</h4>) : null}
                                                            {work.ShortDescription ? (
                                                                <div className="all_workTilecontent">
                                                                    <p dangerouslySetInnerHTML={{ __html: work.ShortDescription }} />
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })} 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
            <AwardsandRecognitions />
            <OurlatestTwoSection />
            <section className="sectionCTA">
                <GetInTouch />
            </section>
        </Layout>
    )

}

export default WorkPage

export const query = graphql`
  query {
    UI {
        dsCaseStudies(sort: "created_at:desc") {
            Title
            Slug
            ShortDescription
            FeaturedImage {
              url
              alternativeText
            }
            created_at
        },
        dsWork {
            Title
            Description
            SeoTag {
              Meta_Title
              Meta_Description
            }
        }   
    }
  }
`